import React from "react";
import { Route, Switch, Redirect, useParams } from "react-router-dom";

import { BaseComponent } from "../utils/BaseComponent";

import Start from "./Start";
import Header from "../components/Header";
import DeveloperList from "./DeveloperList";
import Developer from "./Developer";
import Viewer from "./Viewer";

import "./Router.scss";

const Router: React.FC = () => {
    return (
        <div className="router">
            <Header />
            <div className="router__center">
                <div className="router__main" style={{ flex: `0 0 ${(APP_CONFIG.mainAsideRatio || 0.55) * 100}%` }}>
                    <Switch>
                        <Route path="/start" component={DeveloperList} />
                        <Route path="/developer/:developerId" component={Developer} />
                        <Redirect path="*" to="/start" />
                    </Switch>
                </div>
                <div
                    className="router__aside"
                    style={{ flex: `0 0 ${(1 - (APP_CONFIG.mainAsideRatio || 0.55)) * 100}%` }}
                >
                    <img src={APP_CONFIG.mapUrl} alt="Mapa" />
                </div>
            </div>
            <Route path="/developer/:developerId/:apartmentId" component={Viewer} />
        </div>
    );
};

export default Router;
