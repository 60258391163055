import React from "react";
import { BrowserRouter } from "react-router-dom";

import Start from "./areas/Start";
import Router from "./areas/Router";

interface AppState {}

export default class App extends React.Component<{}, AppState> {
    componentDidMount() {}

    render() {
        return (
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        );
    }
}
