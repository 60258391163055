import React from "react";
import ReactDOM from "react-dom";

import "./scss/index.scss";

import App from "./App";
import { AppConfig } from "./types/Config.types";
// import JSZip from "jszip";
// import { ZIP, ImageElement } from "./types/Zip.types";
// import { ViewStorage } from "./types/View.types";

declare global {
    const APP_CONFIG: AppConfig;

    interface Window {}
}

ReactDOM.render(<App />, document.getElementById("root"));
