import React from "react";

import { DescriptionParams } from "../types/Config.types";

interface DescriptionProps {
    text: string;
    params: DescriptionParams;
}

const Description: React.FC<DescriptionProps> = ({ text, params }) => {
    return text.split("{").reduce((all, fragment) => {
        const fragmentElements = fragment.split("}");
        if (fragmentElements.length !== 2 || !params[fragmentElements[0]]) {
            return (
                <>
                    {all}
                    {fragment}
                </>
            );
        }
        const definition = params[fragmentElements[0]];
        const fragmentActive = definition.type === "Link" ? <a href={definition.url}>{definition.text}</a> : null;
        return (
            <>
                {all}
                {fragmentActive}
                {fragmentElements[1]}
            </>
        );
    }, <></>);
};

export default Description;
