import React, { useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

// import "./Developer.scss";
import Description from "../components/Description";

interface View3dStateOpen {
    isOpen: true;
    id: number;
}

interface View3dStateClose {
    isOpen: false;
}

type View3dState = View3dStateOpen | View3dStateClose;

const Developer: React.FC = () => {
    const { developerId } = useParams<{ developerId: string }>();
    const history = useHistory();

    const developer = APP_CONFIG.developers.find((e) => e.id === developerId);

    // function test() {
    //     const styleSheets = document.styleSheets;
    //     for (let i = 0; i < styleSheets.length; i++) {
    //         const styleSheet: any = styleSheets.item(i);
    //         if (!!styleSheet && !styleSheet.href) {
    //             if (!!styleSheet.cssRules && !!styleSheet.cssRules.length) {
    //                 for (let j = 0; j < styleSheet.cssRules.length; j++) {
    //                     const rule = styleSheet.cssRules[j];
    //                     if (!!rule) {
    //                         if ((rule.selectorText as string).indexOf(".color-secondary") !== -1) {
    //                             rule.style.color = "red";
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    if (!developer) {
        return (
            <div className="developer">
                <div className="developer__go-back">
                    <Link to={"/start"} className="developer__go-back-button">
                        <i className="icon">keyboard_arrow_left</i>Wróć do listy
                    </Link>
                </div>
                <div className="developer__about">
                    <div className="developer__description">
                        <div className="developer__name">Nie znaleziono dewelopera</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="developer">
            <div className="developer__go-back">
                <Link to={"/start"} className="developer__go-back-button">
                    <i className="icon">keyboard_arrow_left</i>Wróć do listy
                </Link>
            </div>
            <div className="developer__about">
                <div className="developer__logo">
                    <img src={developer.logo.src} alt={developer.logo.alt} />
                </div>
                <div className="developer__description">
                    <div className="developer__name">{developer.name}</div>
                    <div className="developer__stand">{developer.standLabel}</div>
                    {developer.description ? (
                        <div className="developer__text">
                            <Description text={developer.description} params={developer.descriptionParams || {}} />
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="developer__apartments">
                {developer.apartments.map((e) => (
                    <div
                        className="developer__apartment"
                        style={{ flex: `0 0 ${100 / (APP_CONFIG.apartmentInRow || 3)}%` }}
                        key={e.id}
                        onClick={() => history.push(`/developer/${developerId}/${e.id}`)}
                    >
                        <div className="developer__apartment-wrapper">
                            <div className="developer__apartment-project">{e.projectName}</div>
                            <div
                                className="developer__apartment-layout"
                                style={{ padding: `${(APP_CONFIG.layoutRatio || 1) * 100}% 0 0 0` }}
                            >
                                <img src={e.layout.src} className="developer__apartment-layout-img" />
                            </div>
                            <div className="developer__apartment-name">{e.name}</div>
                            <div className="developer__apartment-area">
                                {e.area} m<sup>2</sup>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Developer;
