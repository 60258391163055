import React from "react";
import { useParams, useHistory } from "react-router-dom";

const Viewer: React.FC = () => {
    const history = useHistory();

    const { developerId, apartmentId } = useParams<{ developerId: string; apartmentId: string }>();
    const developer = APP_CONFIG.developers.find(e => e.id === developerId);
    if (!developer) return null;

    const apartment = developer.apartments.find(e => e.id === apartmentId);
    if (!apartment) return null;

    function close() {
        history.push(`/developer/${developerId}`);
    }

    return (
        <div className="developer__viewer3d">
            <div className="developer__viewer3d-background" onClick={close}></div>
            <div className="developer__viewer3d-viewer">
                <iframe src={apartment.url3dView} className="developer__viewer3d-iframe" frameBorder="none"></iframe>
                <div className="developer__viewer3d-close" onClick={close}>
                    close
                </div>
            </div>
        </div>
    );
};

export default Viewer;
