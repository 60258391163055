import React from "react";

import "./Header.scss";

export default class Header extends React.Component {
    render() {
        return (
            <div className="header">
                <div className="header__center">
                    {APP_CONFIG.header.logos.map((logo, index) => (
                        <div className="header__logo" key={index}>
                            {logo.src ? <img className="header__logo-img" src={logo.src} alt={logo.alt || ""} /> : null}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
